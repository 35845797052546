<template lang="pug">
div
  //- div(style="display:none;position:absolute") {{turn}}
  .mx-auto.max-width-box-form(v-show="!showMeet")
    div( style="width: 100%; text-align: center;" v-if="preload")
        Spinner
        h3 Conectando...
    div(v-show="!preload")
      audio#attended(@ended="soundEmmited")
        source(src="@/assets/sounds/colmedica_audio.mp3" type="audio/mpeg")
      audio#advice
        source(src="@/assets/sounds/turno_audio.mp3" type="audio/mpeg")
      .row.justify-content-center.Header_container(v-if="!showVideosTikTok && !showNotHourToTurn && !showExpired && !shwoEnded&& !showExpiredLink && !showCanceled")
        .col-11.header
          div.text-center
            img.client_logo(:src="Logo" v-openlog)
      .main_box(v-if="!showVideosTikTok && !showNotHourToTurn && !showExpired && !shwoEnded && !showExpiredLink && !showCanceled")
        h2.TitleSection ¡Turno asignado! {{username}}
        .px-4
          .boxInfo.col-12.mt-4.pt-2.pb-2(style="z-index: 1;")
            h4.text-center.AssignmentText Tu turno asignado es:
            //- h4.text-middle.color-colmedica-gray número:
            h3.text-center.font-weight-bold.ImportantAsignment {{ assingTurn }}
            h5(v-show="!initMeet").mt-1.text-center.mb-1 Posición en la fila:
            h5(v-show="!initMeet").text-middle.font-larger {{ typeof turnsInFrontBSIX === "number" ? turnsInFrontBSIX + 1 : "1" }}
            //- h5(v-show="!initMeet").mt-1.text-center.mb-1 Próximo turno:
            //- h5(v-show="!initMeet").text-middle.font-larger {{ actualTurn }}
            h5(v-show="initMeet").mt-5.text-middle.color-colmedica-gray Un momento por favor...
            //- p Te enviaremos un correo electrónico y un mensaje de texto con un link de ingreso a la videollamada cuando tu turno este próximo  a ser atendido
            p Te enviaremos un mensaje de texto con un link de ingreso a la videollamada cuando tu turno sea próximo a ser atendido.
            //- a.Link La notificación te la enviaremos a tu correo electrónico {{email}} y a tu número celular {{phone}}
            a.Link La notificacion te la enviaremos a tu número celular {{phone}}
          p.mt-4 ¡Esperemos juntos!
          p.mb-0 A continuacion hemos preparado una serie de contenidos para ti
            //- p.color-colmedica En este momento presentamos congestión en nuestro canal, si ya enviaste foto de la orden médica y no vas a acceder inmediatamente al servicio, puedes cancelar la solicitud y te daremos respuesta al correo y te contactaremos si requerimos información adicional..

            //- .mt-3.color-colmedica-gray
            //-   b-form-checkbox(
            //-     :class="{'audioNotification isDisabled': !audioNotification, 'audioNotification isEnabled': audioNotification}"
            //-     id="checkbox-1"
            //-     v-model="audioNotification"
            //-     @click="audioNotifyClick"
            //-     name="checkbox-1"
            //-   ) Notificarme con audio cuando sea atendido
            //- iframe.YoutubeVideoFrame(width='349.22' height='190.92px' src='https://www.youtube.com/embed/KtS5V3aQS6g' title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen='')
            //- p.mt-5.text-center Debido a la situación de orden público en este momento presentamos demoras en la atención de nuestra Oficina Virtual,  gracias por tu comprensión.
      //- .w--100.text-center
      //-   video(id="video" src="https://bsix.assets.gobrilliant.com/colmedica/Colmedica_Promo_Video.mp4" poster="https://bsix.assets.gobrilliant.com/colmedica/VideoColmedicaPoster.jpg" controls)
      //-   .col-12.contain.color-colmedica-gray
      //-     p(style="font-size: 1.3rem; text-align: center;") Bienvenido a Autorizaciones médicas Colmédica
      
      
      //- div(class="text-right my-3") {{ version }}
      div(v-if="showVideosTikTok")
        TikTokVideos(
          :assignTurn="assingTurn"
          :actualTurn="actualTurn"
          :turnsInFront="turnsInFrontBSIX"
          :goBack="() => showVideosTikTok = false"
        )
      div(v-if="showExpired")
        Header.mt-0
        .mx-auto.max-width-box-form
          svg(width='32' height='32' viewbox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg').text-center.d-block.mx-auto.mt-5
            path(d='M16 32C24.8366 32 32 24.8366 32 16C32 7.16345 24.8366 -6.26249e-07 16 -1.39876e-06C7.16345 -2.17128e-06 2.17128e-06 7.16344 1.39876e-06 16C0.00937525 24.8326 7.16733 31.9906 16 32Z' fill='#3290E0')
            path(d='M15.9987 24.9805C17.1446 24.9805 18.0117 24.3824 18.0117 23.4165L18.0117 14.5565C18.0117 13.5895 17.1448 12.9935 15.9987 12.9935C14.8807 12.9935 13.9856 13.6144 13.9856 14.5565L13.9856 23.4165C13.9857 24.3595 14.8807 24.9805 15.9987 24.9805ZM15.9987 10.9974C17.0997 10.9974 17.9957 10.1014 17.9957 8.99949C17.9957 7.8995 17.0997 7.00344 15.9987 7.00344C14.8977 7.00344 14.0026 7.8995 14.0026 8.99949C14.0027 10.1015 14.8977 10.9974 15.9987 10.9974Z' fill='#F4F4F4')
          //- h2(v-if="!showExceededTime && !showExpiredLink && !showCanceled").mt-3.TitleSection.px-4.pb-3 Lamentablemente tu turno ya fue llamado
          h2(v-if="!showExceededTime && showExpiredLink && !showCanceled").mt-3.TitleSection.px-4.pb-3 Lamentablemente el link ya no es válido
          h2(v-if="!showExceededTime && !showExpiredLink && showCanceled").mt-3.TitleSection.px-4.pb-3 Lamentablemente la cita fue cancelada
          h2(v-if="showExceededTime && !showExpiredLink && !showCanceled").mt-3.TitleSection.px-4.pb-3 Lamentablemente el tiempo para tu videollamada ya expiró
          h2(v-if="showExceededTime").mt-3.TitleSection.px-4.pb-3 Tu cita estaba programada para las {{ hour }}
          .form-data.mb-3.mt-5.py-4.mx-1.px-4.mx-4
            p.font-16.mb-5.text-center.px-4 No hay problema, presiona continuar para ver las opciones que te podemos ofrecer:
            b-button(
              variant="bluecolmedica"
              type="button"
              @click="goHome()").w-100.py-2.mx-1.text-center.buttonTurnero.mt-3.mb-2
              span.font-lg-16 Continuar
      div(v-if="shwoEnded")
        Header.mt-0
        .mx-auto.max-width-box-form
          svg(width='32' height='32' viewbox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg').text-center.d-block.mx-auto.mt-5
            path(d='M16 32C24.8366 32 32 24.8366 32 16C32 7.16345 24.8366 -6.26249e-07 16 -1.39876e-06C7.16345 -2.17128e-06 2.17128e-06 7.16344 1.39876e-06 16C0.00937525 24.8326 7.16733 31.9906 16 32Z' fill='#3290E0')
            path(d='M15.9987 24.9805C17.1446 24.9805 18.0117 24.3824 18.0117 23.4165L18.0117 14.5565C18.0117 13.5895 17.1448 12.9935 15.9987 12.9935C14.8807 12.9935 13.9856 13.6144 13.9856 14.5565L13.9856 23.4165C13.9857 24.3595 14.8807 24.9805 15.9987 24.9805ZM15.9987 10.9974C17.0997 10.9974 17.9957 10.1014 17.9957 8.99949C17.9957 7.8995 17.0997 7.00344 15.9987 7.00344C14.8977 7.00344 14.0026 7.8995 14.0026 8.99949C14.0027 10.1015 14.8977 10.9974 15.9987 10.9974Z' fill='#F4F4F4')
          h2.mt-3.TitleSection.px-4.pb-3 La videollamada ha finalizado
          .form-data.mb-3.mt-5.py-4.mx-1.px-4.mx-4
            p.font-16.mb-5.text-center.px-4 Gracias por utilizar nuestros servicios. Te esperamos en una próxima ocasión.
            p.font-18.mb-5.text-center.px-4 Recuerda que también puedes solicitar tus autorizaciones médicas mediante nuestros canales virtuales App y Web Colmédica de manera rápida y segura.
      .px-2(v-if="showNotHourToTurn && !showExpired && !shwoEnded")
        Header.mt-0
        h2.mt-5.TitleSection.px-4.pb-3 Aún no es tiempo para tu videollamada
        .form-data.mb-3.mt-5.py-4.mx-1.px-4.mx-3
          p.font-16.mb-0.text-start Nombre:
            strong.ml-1 {{ codeInfo.customer.firstName }}
          p.font-16.mb-0.text-start Fecha y hora:
            strong.ml-1 {{ dateWithoutHour }}
            strong.ml-2 {{ hour }}
          p.font-16.mb-0.text-start Servicio:
            strong.ml-1 {{ codeInfo.reason.servicioSelected }}
          p.font-16.mb-0.text-start Modalidad de atención:
            strong.ml-1 {{ codeInfo.reason.modalidad }}
          p.font-16.mt-4.text-center.text-center Tu videollamada inicia en:
            strong.ml-1.d-block(v-if="duration.asHours() < 24")
              span(v-if="parseInt(duration.asHours()) > 0") {{ parseInt(duration.asHours()) }} {{ parseInt(duration.asHours()) === 1 ? "hora" : "horas" }}
              span(v-if="parseInt(duration.asHours()) < 1") {{ parseInt(duration.asMinutes()) }} {{ parseInt(duration.asMinutes()) === 1 ? "minuto" : "minutos" }}
            strong.ml-1.d-block(v-else) {{ parseInt(duration.asDays()) }} {{ parseInt(duration.asDays()) === 1 ? "día" : "días" }}
        p.mt-4.text-center ¡Esperemos juntos!
        p.mb-0.text-center A continuación hemos preparado una serie de contenidos para ti
      .main_bottom.mt-5.pt-5(v-if="showExpired || shwoEnded")
        b-button(variant="whitecolmedica"  @click="goHome").buttonBack.mx-auto.d-block.mb-2 Ya puedes cerrar ésta ventana
        //- a(
        //-   @click="goToSchedule"
        //- ).mx-2.AnotherLink.mt-1.pt-0.text-center.d-block Ver videollamadas programadas
      .main_bottom.mx-3(v-if="!showVideosTikTok && !showExpired && !shwoEnded")
        //- div(v-show="1>2" class="div_center_button" style="bottom: 14vh;")
        //-   b-button(v-b-modal.wait-modal variant="bluecolmedica" class = "modal_button notificationSMSDialog") Notifícame cuando sea mi turno
        b-button(
          variant="bluecolmedica"
          type="button"
          @click="showVideosTikTok = true").w-100.py-2.mx-1.text-center.buttonTurnero.mt-3
          span.font-lg-16 Muéstrame los videos
        .div_center_button
          a(
            v-b-modal.cancel-modal
            v-if="!showVideosTikTok && !showNotHourToTurn"
          ).color-primary Cancelar Solicitud
          //- u(
          //-   @click="goToSchedule"
          //-   v-else-if="showNotHourToTurn"
          //- ).modal_button.cancelTurnDialog Ver videollamadas programadas
      b-modal(id="msm-modal" footerClass='p-2 border-top-0' headerClass='p-2 border-bottom-0' centered=true hide-footer)
        div(class="d-block text-center modal_inside" )
          p(class="my-4 modal_highlight_text") ¡Listo!
          p(class="my-4") Te llegará un mensaje de texto en {{waiting_time}} aproximadamente
          p(class="my-4") Ya puedes cerrar esta ventana
        div(class="div_center_button")
          b-button(@click="$bvModal.hide('msm-modal')" variant="outline-dark" class="div_center_button") Ok

      b-modal(id="cancel-modal" footerClass='p-2 border-top-0' headerClass='p-2 border-bottom-0' centered=true hide-footer hide-header)
        div(class="p-2 position-relative w-auto h-auto")
          div(class="d-block text-center modal_inside" )
            p(class="my-4 modal_highlight_text") Estás a punto de cancelar la videollamada
            p(class="my-4") Esto cancelará el proceso de atención con el asesor Colmédica
            p(class="my-4") ¿Qué deseas hacer?
          div(class="position-relative")
            b-button(@click="cancelTurnHandler()" variant="outline-danger" class="btn-block cancelTurnConfirmation") Cancelar la videollamada
            b-button(@click="$bvModal.hide('cancel-modal')" variant="bluecolmedica" class="btn-block") Continuar con el asesor Colmedica

      b-modal(id="new-turn-modal" footerClass='p-2 border-top-0' headerClass='p-2 border-bottom-0' centered=true hide-footer hide-header no-close-on-backdrop)
        div(class="d-block text-center modal_inside" )
          p(class="my-4 modal_highlight_text") Te hemos asignado el turno
            h3.text-middle.color-colmedica-light.font-weight-bold {{ assingTurn }}
          div(class="position-relative")
            b-button(@click="continueTurn" variant="bluecolmedica" class="btn-block" style="height: 65px; font-size: xx-large;") Continuar
      b-modal(footerClass='p-2 border-top-0' headerClass='p-2 border-bottom-0' centered=true hide-footer hide-header no-close-on-backdrop)#confirm-modal
        div(class="d-block text-center modal_inside" )
          p(class="my-4 modal_highlight_text") Tu asesor esta listo para atenderte
            //- p(class="my-4") Continuar con el asesor Colmedica
          div(class="position-relative")
            b-button(@click="resolveconfirm" variant="bluecolmedica" class="btn-block" style="height: 72px;") Conectar con asesor
  .ContainerMeetingOf(v-if="initMeet")
    Meeting(v-show="showMeet" meetType="customer" :meetId="meetingId" @endMeet="endMeet" :username="username" :isComponent="true" @attendees="attendees")
</template>

<script>
import { mapState, mapActions } from "vuex";
// import Mobile from "../utils/evalmobile";
import _get from "lodash/get";
import kuid from "kuid";
import moment from "moment";

import LogoColmedica from "@/assets/colmedica_logo.png";
import asterisker from "@/helpers/asteriskHidden";

import Meeting from "./Meeting.vue";
import TikTokVideos from "./autoservice/TikTokVideos";
import Header from "./autoservice/components/Header.vue";
import { setCorrelation } from "../helpers/Meet";
import { getOneUseToken } from "../helpers/Meet";
import { utf8_to_b64 } from "@/utils/decoder";
// import LS from "@/helpers/localStorage";
import Spinner from "./components/Spinner.vue";

const axios = require("axios");

export default {
  name: "Turno",

  components: {
    Meeting,
    TikTokVideos,
    Header,
    Spinner
  },

  data: () => ({
    name: "Susan Fajardo",
    date: "13/02/2022, 9:00a.m.",
    serviceData: "Cardiologia",
    modeAtt: "Videollamada",
    showExpired: false,
    shwoEnded: false,
    showExceededTime: false,
    showNotHourToTurn: false,
    showVideosTikTok: false,
    showExpiredLink: false,
    showCanceled: false,
    LogoColmedica,
    timerSound: null,
    lastTimerSound: null,
    audioNotification: true,
    // isiOS: Mobile.iOS(),
    isiOS: true,
    turnsInFrontCache: null,
    waitingTimeCache: null,
    timeAverageDirect: false, // TODO: Change this value if require to show DebQ api average time result
    errorCoorelationRequest: false,
    errorStartup: false,

    // meet
    initMeet: false,
    showMeet: true,
    showAudioNotification: true,

    connected: false,
    loading: false,

    resolveconfirm: null,
    resolveNewturn: null,

    codeInfo: {},
    duration: null,
    preload: false,
    countCooreRetry: 0,

    showPosition: 0,
  }),

  computed: {
    ...mapState({
      time_received_turn: state => state.time_received_turn,
      turnUUID: state => state.turnUUID,
      version: state => state.version,
      environment: state => state.environment,
      company: state => state.company,
      company_queue: state => state.company_queue,
      sessionMaster: state => state.sessionMaster,
      this_user_data: state => state.client_data,
      turn: state => state.turn,
      tramite_info: state => state.tramite_info,
      env: state => state.env,
      turnCoorelation: state => state.turnCoorelation,
      client: state => state.autoservice.client,

      username() {
        if(this.client) return `${this.client.Nombres} ${this.client.PrimerApellido} ${this.client.SegundoApellido || ''} `;
        else return ""
      },

      turn_from_code: state => state.autoservice.turn_from_code
    }),

    demoMode() {
      return this.env.VUE_APP_DEMO_MODE === "true";
    },

    LogoDemo() {
      return this.env.VUE_APP_DEMO_LOGO;
    },

    debMediaServer() {
      return this.env.VUE_APP_DEBMEDIA_SERVER;
    },

    Logo() {
      return this.demoMode && this.LogoDemo
        ? this.LogoDemo
        : this.env.VUE_APP_LOGO || this.LogoColmedica;
    },

    iOS() {
      return !!this.isiOS;
    },

    formalityName() {
      if (this.turn && this.company_queue) {
        let formalityData = this.company_queue.filter(
          t => t.id === _get(this.turn, "jsonDetails.queue.id")
        );
        return _get(formalityData, "[0].name");
      }
      return "";
    },

    companyName() {
      return _get(this.company, "name");
    },

    actualTurn() {
      return _get(this.turn, "jsonDetails.actualTurn", "canceled");
    },

    assingTurn() {
      if(this.turn && this.turn.status) { 
        if(this.turn.status == "FINALIZED") return "";
      }
      return _get(this.turn, "jsonDetails.turn");
    },

    turnId() {
      return _get(this.turn, "code");
    },
    turnDetails() {
      return this.turnsInFrontCache;
    },
    turnsInFront() {
      return _get(this.turn, "jsonDetails.estimatorResult.turnsInFront");
    },
    turnsInFrontBSIX() {
      return _get(this.turn, "jsonDetails.estimatorResult.turnsInFrontBSIX");
    },
    waitingTime() {
      return _get(this.turn, "jsonDetails.estimatorResult.waitingTime");
    },
    status() {
      return _get(this.turn, "status");
    },
    userPhone() {
      if (
        _get(this.this_user_data, "phone.length") &&
        this.this_user_data.phone.length > 4
      ) {
        return (
          "*".repeat(this.this_user_data.phone.length - 4) +
          this.this_user_data.phone.slice(-4)
        );
      } else {
        return "";
      }
    },
    waiting_time() {
      if (this.time_received_turn === null) return "60 minutos";
      var now = moment();
      var turn_time = moment.unix(this.time_received_turn);
      var mins_elapsed = now.diff(turn_time, "minute");
      var num_min = Math.ceil(
        this.turnsInFrontCache // Previous: jsonDetails.averageWaitingTime
      );
      var time_to_attend = num_min - mins_elapsed;
      if ((this.timeAverageDirect ? num_min : time_to_attend) > 1) {
        return (
          (this.timeAverageDirect ? num_min : time_to_attend).toString() +
          " minutos"
        );
      } else {
        return "1 minuto";
      }
    },

    phone() {
      let pure = _get(this.client, "Celular") || "";
      return asterisker(String(pure), 0, 4);
    },

    email() {
      let pure = _get(this.client, "Correo") || "";
      let splited = pure.split("@");
      return `${asterisker(splited[0], 2, 2)}${
        splited[1] ? "@" + asterisker(splited[1], 0, splited[1].length - 2) : ""
      }`;
    },

    // meet

    meetingId() {
      return this.turnCoorelation?.code_title;
    },

    dateWithoutHour() {
      return moment(this.codeInfo.startAt).format("YYYY/MM/DD");
    },

    hour() {
      return moment(this.codeInfo.startAt).format("hh:mm a");
    },

    checkInTolerance(){
      let tolerance;
      try {
        tolerance = JSON.parse(this.codeInfo.schedule.checkInTolerance);
      } catch (error) {
        tolerance = {};
      }
      return tolerance;
    }
  },

  async created() {
    let code = this.$route.query.code;
    let type = this.$route.query.type;

    // Check code and type
    if (code) {
      // 1. appointments 2. turns
      if (type == 1) {
        // Request to appointment by code
        axios
          .get(
            `${this.env.VUE_APPOINTMENTS_DEBMEDIA_SERVER}api/v2/appointments/${code}`,
            {
              headers: {
                "x-api-token": this.env.VUE_APPOINTMENTS_DEBMEDIA_API_KEY
              }
            }
          )
          .then(async res => {
            let info = res.data;

            this.codeInfo = info;
            this.codeInfo.reason = JSON.parse(info.reason);
            console.log(this.codeInfo);

            // check appointment status
            if (info.status != "CONFIRMED") {
              console.log(info.status);
              switch (info.status) {
                case "CANCELED_INTERNALLY":
                case "CANCELED":
                  this.showCanceled = true;
                  this.showExpiredLink = false;
                  this.showExceededTime = false;
                  break;
                case "ABSENT":
                  this.showCanceled = false;
                  this.showExpiredLink = false;
                  this.showExceededTime = true;
                  break;
                case "CHECKED_IN":
                  this.showCanceled = false;
                  this.showExpiredLink = true;
                  this.showExceededTime = false;
                  break;
                default:
                  break;
              }
              this.showMeet = false;
              return (this.showExpired = true);
            }

            // return this.makeCodeToUrl();

            // Check time for appointment
            this.duration = moment.duration(
              moment(info.startAt).diff(moment())
            );
            var minutes = this.duration.asMinutes();

            // 5 minutes left for the appointment
            if (minutes > this.checkInTolerance.before) {
              this.showMeet = false;
              this.shwoEnded = false;
              this.showExpired = false;
              this.showExceededTime = false;
              let intervalM = setInterval(() => {
                this.duration = moment.duration(
                  moment(info.startAt).diff(moment())
                );
                var minutes = this.duration.asMinutes();
                // 5 minutes left for the appointment
                if (minutes <= this.checkInTolerance.before) {
                  clearInterval(intervalM);
                  // fetch turn
                  this.makeCodeToUrl();
                }
              }, 1000);
              return (this.showNotHourToTurn = true);
            } else {
              // 10 minutes exceeded show error
              let exceededTime = parseInt(minutes) * -1;
              if (exceededTime > this.checkInTolerance.after) {
                this.showMeet = false;
                this.showExceededTime = true;
                return (this.showExpired = true);
              } else {
                // fetch turn
                this.makeCodeToUrl();
              }
            }
          })
          .catch(err => {
            console.log(err);

            this.$router.push({
              name: "Decoder",
              query: { err: err }
            });
          });

        // Code to turn
      } else {
        this.showMeet = false;
        this.preload = true;

        // Fetch turn with user info
        await this.fetchTurnData(code);

        // get cuuid from extrafields for coorrelation
        console.log(this.turn_from_code);
        let UUID = this.turn_from_code[0]?.extra_fields.match(
          /(turn_assigner[/A-Z])\w+/g,
          "\\$&"
        );
        console.log(UUID);
        UUID = UUID[0]?.split("/");
        if (UUID.length > 0) {
          console.log(UUID);
          await this.setTurnUUID(UUID[1]);
          await this.getTurnInfo(code);

          console.log(this.turnUUID);
          if (this.turn) this.checkStatus(this.status);
        } else {
          console.error("In turnToMeetingAction", "Uuid no exist");
          this.errorCoorelationRequest = true; // TODO: How show this error? How to recover state?
        }

        this.preload = false;
      }
    }
  },

  mounted() {
    if (this.$route.query.code) return;
    // const random = Math.random() * 10;
    // const random2 = Math.random() * 10;

    // if (random <= 5) {
    //   this.showNotHourToTurn = true;
    // }

    // if (random2 <= 5) {
    //   this.showExpired = true;
    // }

    setTimeout(async () => {
      console.log("ESTADO TURNO");
      console.log(this.actualTurn);

      if (this.actualTurn == "canceled") this.cancelTurnHandler();

      if (window.location.href.indexOf("fv_atril") == -1) {
        this.checkPermissions();
      }

      // show modal turn
      if (this.assingTurn) this.$bvModal.show("new-turn-modal");
      // console.log('deberia mostral modal')
      // await new Promise(resolve => this.resolveNewturn = resolve );
      // this.showMeet = false;
      // this.$bvModal.hide('new-turn-modal');

      this.startup();

      let font = _get(this.this_user_data, "fu");
      font = !font || font == "" ? "ad" : font;
      // tracking
      let trackingData = {
        uuid: window.person.id,
        accion: "customer-turno-asignado",
        debmedia_turn_code: this.turnId,
        url_origen: window.location.href,
        origen: font,
        mensajes: ""
      };

      this.sendTracking(trackingData);

      try {
        var hidden, visibilityChange;
        if (typeof document.hidden !== "undefined") {
          hidden = "hidden";
          visibilityChange = "visibilitychange";
        } else if (typeof document.mozHidden !== "undefined") {
          hidden = "mozHidden";
          visibilityChange = "mozvisibilitychange";
        } else if (typeof document.msHidden !== "undefined") {
          hidden = "msHidden";
          visibilityChange = "msvisibilitychange";
        } else if (typeof document.webkitHidden !== "undefined") {
          hidden = "webkitHidden";
          visibilityChange = "webkitvisibilitychange";
        } else {
          console.error("Page Visibility API not supported.");
        }

        document.addEventListener(
          visibilityChange,
          () => {
            // tracking
            let trackingData = {
              uuid: window.person.id,
              accion: document[hidden]
                ? "customer-visibilidad-oculta"
                : "customer-visibilidad-visible",
              debmedia_turn_code: this.turnId,
              url_origen: window.location.href,
              origen: font,
              mensajes: ""
            };

            this.sendTracking(trackingData);
          },
          false
        );
      } catch (error) {
        // tracking
        let trackingData = {
          uuid: window.person.id,
          accion: "customer-visibilidad-fail",
          debmedia_turn_code: this.turnId,
          url_origen: window.location.href,
          origen: font,
          mensajes: ""
        };

        this.sendTracking(trackingData);
      }
    }, 1000);
  },

  watch: {
    status(val) {
      console.log(val);
      this.checkStatus(val);

      // tracking
      if (val) {
        setTimeout(() => {
          let trackingData = {
            uuid: window.person.id,
            accion: "customer-cambio-status: " + val,
            debmedia_turn_code: this.turnId,
            url_origen: window.location.href,
            origen: _get(this.this_user_data, "fu") || "ad",
            mensajes: ""
          };

          this.sendTracking(trackingData);
        }, 1000);
      }
    },
    // turnId(val, oldVal) {
    //   console.log('turnId')
    //   console.log(val, oldVal)
    //   // if (oldVal && !val) this.cancelTurnHandler();
    // },
    turnsInFront(val) {
      if (val) this.turnsInFrontCache = val;
    },
    waitingTime(val) {
      if (val) this.waitingTimeCache = val;
    },
    async actualTurn(val) {
      if (!this.audioNotification && val != "canceled") {
        document.getElementById("advice").play();
      }
      if (val == "canceled") this.cancelTurnHandler();
    },
    audioNotification(val) {
      if (val) {
        document.getElementById("advice").currentTime = 0;
        document.getElementById("advice").play();
        // setTimeout(() => {
        //   document.getElementById("advice").currentTime = 0;
        //   document.getElementById("advice").play();
        // }, 10000);
      }
    },
    turn(val) {
      if (!val) this.cancelTurnHandler();
    }
  },

  beforeDestroy() {
    if (this.lastTimerSound) clearTimeout(this.lastTimerSound);
    if (this.timerSound) clearTimeout(this.timerSound);
  },

  methods: {
    ...mapActions({
      cancelTurn: "cancelTurn",
      getTurn: "getTurn",
      getTurnCoorelation: "getTurnCoorelation",
      putLog: "log/put",
      sendTracking: "virtualrow/handleTracking",
      goToView: "autoservice/goToView",
      updateB64Final: "autoservice/updateB64Final",
      fetchTurnData: "autoservice/fetchTurnData",
      getTurnInfo: "getTurnInfo",
      setTurnUUID: "setTurnUUID"
    }),

    audioNotifyClick() {
      // console.log("Notificacion state", this.audioNotification);
    },

    async establishCorrelation() {
      await setCorrelation({
        kuidId: this.turnUUID,
        turnId: kuid(),
        session: this.sessionMaster,
        turn_code: this.turn?.code
      });
    },

    soundEmmited() {
      if (this.timerSound) clearTimeout(this.timerSound);
      this.timerSound = null;
      // this.lastTimerSound = setTimeout(() => {
        // if (this.status !== "ANNOUNCED") this.cancelTurnHandler();
        // else this.turnToMeetingAction();
        this.turnToMeetingAction();
      // }, 1000);
    },

    async checkStatus(newStatus) {
      console.log(newStatus);
      switch (newStatus) {
        case "ANNOUNCED":
          this.showVideosTikTok = false;
          if (this.audioNotification) {
            if (document.getElementById("attended")) {
              try {
                // this.timerSound = setTimeout(() => {

                // }, 2000);
                try {
                  if (
                    document.getElementById("attended") &&
                    document.getElementById("attended").currentTime > 0
                  )
                    return;
                } catch (error) {
                  this.$rollbar.warning("Invalid turn audio state", error);
                }
                this.soundEmmited();
                // await document.getElementById("attended").play();
                document.getElementById("attended").play();
              } catch (error) {
                this.$rollbar.info("Sound of meeting cant play");
                // if (this.status !== "ANNOUNCED") this.cancelTurnHandler();
                // else {
                // wait for user
                // this.$bvModal.show('confirm-modal')
                this.$bvModal.hide("new-turn-modal");
                // await new Promise(resolve => this.resolveconfirm = resolve);
                // this.$bvModal.hide('confirm-modal')
                this.turnToMeetingAction();
                // }
              }
            }
          } else {
            // if (this.status !== "ANNOUNCED") this.cancelTurnHandler();
            // else {
            // wait for user
            // this.$bvModal.show('confirm-modal')
            this.$bvModal.hide("new-turn-modal");
            // await new Promise(resolve => this.resolveconfirm = resolve);
            // this.$bvModal.hide('confirm-modal')
            this.turnToMeetingAction();
            // }
          }
          break;
        case "WAITING_TO_BE_CALLED":
          this.showMeet = false;
          break;
        case "FINALIZED":
          this.showVideosTikTok = false;
          this.showMeet = false;
          this.showExpired = false;
          this.initMeet = false;
          this.showExceededTime = false;
          this.shwoEnded = true;
          localStorage.clear();
          this.$router.push("/finished");
          break;
        case "REVOKED":
          this.showVideosTikTok = false;
          this.showMeet = false;
          this.showExpired = true;
          this.initMeet = false;
          this.showExceededTime = false;
          this.shwoEnded = false;
          localStorage.clear();
          this.$router.push("/finished");
          break;
        case "CANCELED_BY_USER":
          this.showVideosTikTok = false;
          localStorage.clear();
          this.$router.push("/finished");
          // case "ABSENT":
          this.cancelTurnHandler();
          break;

        default:
          break;
      }
      return newStatus;
    },

    goHome() {
      this.goToView("Home");
      setTimeout(() => {
        this.$router.push("/ad/");
      }, 1000);
    },

    goToSchedule() {
      this.goToView("ToSchedule");
      setTimeout(() => {
        this.$router.push("/ad/");
      }, 1000);
    },

    goToConfirmUpload() {
      this.goToView("ConfirmUpload");
      setTimeout(() => {
        this.$router.push("/ad/");
      }, 1000);
    },

    async turnToMeetingAction() {
      try {
        // await this.getTurnCoorelation(this.turnUUID);
        if (!this.audioNotification && this.actualTurn != "canceled")
          document.getElementById("advice").play();
        // LS.setItem("audioNotification", this.audioNotification);
        // this.$router.push(`/meeting/${code_title}`);
        this.initMeet = true;
        this.showMeet = true;
        this.showAudioNotification = false;

        setTimeout(() => {
          let trackingData = {
            uuid: window.person.id,
            accion: "customer-obtencion-id-videollamada",
            debmedia_turn_code: this.turnId,
            url_origen: window.location.href,
            origen: _get(this.this_user_data, "fu") || "ad",
            mensajes: this.meetingId
          };

          this.sendTracking(trackingData);
        }, 1000);
      } catch (error) {
        console.error("In turnToMeetingAction", error);
        this.errorCoorelationRequest = true; // TODO: How show this error? How to recover state?
        console.log("Como que algo no cuadra")
        console.log(this.countCooreRetry)
        this.putLog({
          name:
            "Turno. Error restrictivo. No fue posible establecer la coorelación o el turno. En turnToMeetingAction",
          checked: false,
          message: error
        });

        setTimeout(() => {
          this.countCooreRetry++
          if(this.countCooreRetry < 3){
            this.turnToMeetingAction();
          }
        }, 1000);
      }
    },

    async startup() {
      try {
        // try {
        //   await this.establishCorrelation();
        // } catch (error) {
        //   console.log("ERROR Correlation");
        //   console.log(error)
        // }
        this.putLog({
          name: "Turno. Se estableció la coorelación."
        });
        await this.getTurn();
        this.putLog({
          name: "Turno. Se creó el turno."
        });
        if (this.turn) this.checkStatus(this.status);
        if (!this.turnId) this.cancelTurnHandler();
        if (this.turnsInFront) this.turnsInFrontCache = this.turnsInFront;
        if (this.waitingTime) this.waitingTimeCache = this.waitingTime;
      } catch (error) {
        this.showMeet = false;
        console.log("EERROR");
        console.log(error);
        this.errorStartup = true;
        this.putLog({
          name:
            "Turno. Error restrictivo. No fue posible establecer la coorelación o el turno.",
          checked: false,
          message: error
        });
        if (this.turn) this.checkStatus(this.status);
      }
    },
    async cancelTurnHandler() {
      try {
        await this.cancelTurn();
        this.$bvModal.hide("cancel-modal");
      } catch (error) {
        console.error("Cant cancel turn in component", error);
      }
      // this.$router.push({ name: "Canceled" });
      this.showVideosTikTok = false;
      this.shwoEnded = true;
      this.showMeet = false;
    },

    // Meet

    async endMeet() {
      await this.cancelTurn({ local: true });
      this.shwoEnded = true;
      this.initMeet = false;
    },

    checkPermissions() {
      let constraints = { audio: true, video: true };
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => {
          console.log("permissions success");
          console.log(stream);
        })
        .catch(err => {
          if (err.name == "NotAllowedError") {
            if (err.message == "Permission denied") {
              return;
            }
            this.checkPermissions();
          }
        });
    },

    attendees(loading) {
      this.loading = loading;
      if (!loading) {
        // Check connection
        this.checkConnection();
      }
    },

    checkConnection() {
      let time;
      setTimeout(() => {
        console.log("Check connection...");
        console.log("loading: " + this.loading);
        if (this.loading) {
          console.log("Reinstanciando...");
          this.initMeet = false;
          this.showMeet = false;
          time = setTimeout(() => {
            console.log("Reinstanció la videollamada");
            this.initMeet = true;
            this.showMeet = true;
            this.checkConnection();
            this.loading = false;
          }, 2000);
        } else {
          this.initMeet = true;
          this.showMeet = true;
          clearTimeout(time);
        }
      }, 10000);
    },

    continueTurn() {
      document.getElementById("advice").play();
      this.$bvModal.hide("new-turn-modal");
    },

    async makeCodeToUrl() {
      // Make JSON
      let to = await getOneUseToken();
      // let ma = "cliente";
      let su = Number(this.env.VUE_APP_DEBMEDIA_DEFAULT_BRANCH);
      let tr = Number(
        this.codeInfo.reason?.tramite ||
          this.env.VUE_APP_DEBMEDIA_DEFAULT_TRAMITE
      );
      let na = this.codeInfo.customer.firstName;
      let ap = this.codeInfo.customer?.lastName || "";
      let la = "";
      console.log("aqui asigna ma")
      console.log(this.codeInfo.reason?.marcacion)
      let ma = this.codeInfo.reason?.marcacion;
      let ti = this.codeInfo.customer.cuit;
      let nu = this.codeInfo.customer.dni;
      let ce = this.codeInfo.customer.phone;
      let so = this.codeInfo.reason?.so;
      let fu = this.codeInfo.reason?.fu + "_appointment";
      let br = this.codeInfo.reason?.br;
      let os = this.codeInfo.reason?.os;
      let ac = this.codeInfo.code; // appointment code

      let jsonFinal = {
        to,
        tr,
        su,
        fu,
        ma,
        na,
        ap,
        la,
        ti,
        nu,
        ce,
        so,
        br,
        os,
        ac
      };
      this.updateB64Final(jsonFinal);

      let jsonB64 = JSON.stringify({
        ...jsonFinal,
        sv: this.service,
        to
      });

      this.$router.push({
        name: "Decoder",
        query: { pr: utf8_to_b64(jsonB64) }
      });
    }
  }
};
</script>
<style scoped>
.mediaServer {
  position: absolute;
  width: 100%;
  bottom: 10px;
  font-size: 0.6rem;
}
.task_name {
  font-size: 1.5em;
  padding-bottom: 3vh;
  padding-top: 50px;
  font-family: Helvetica-bold;
}

.main_box {
  width: 100%;
  height: 100%;
  position: relative;
  margin: auto;
  text-align: center;
  font-size: 1em;
  /* padding-top: 35.75px; */
}

.main_bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info_box {
  position: relative;
  z-index: 0;
  background-color: #00599d;
  color: white;
  border-radius: 7px;
  height: 256px;
}

.client_logo {
  width: 129.3px;
  height: auto;
}

.personel_logo {
  width: 50%;
  max-width: 275px;
  height: auto;
}

.clock_logo {
  width: 45px;
  height: auto;
}

.header {
  margin-top: 3vh;
  margin-bottom: 3vh;
}

.text-header-container {
  position: relative;
  height: 10vh;
  width: 50%;
  float: left;
}

.text-vetical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 1.2em;
  font-weight: 600;
}

.div_center_button {
  position: absolute;
  bottom: 3vh;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  left: 0;
  right: 0;
  width: 100%;
  padding: 20px 20px;
}

.boxInfo {
  max-width: 347px;
  margin: auto;
  margin-top: 40px;
  background-color: #f9f9f9;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
  border-radius: 21.4881px;
}

.buttonTurnero {
  width: 100%;
  display: block;
  margin: auto;
  max-width: 317.25px;
}

.color-primary {
  color: #3290e0;
}

.cancelTurnDialog {
  color: var(--color-gray);
}

.form-data {
  margin-top: 40px;
  background-color: #f9f9f9;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
  border-radius: 21.4881px;
}

.modal_highlight_text {
  color: #00599d;
  font-size: 1.1em;
  font-family: Helvetica-bold;
}

.modal_inside {
  color: #979797;
  font-family: Helvetica-medium;
}

.AssignmentText {
  font-size: 21px;
  line-height: 28px;
}

.ImportantAsignment {
  color: var(--color-secondary);
}

.Header_container {
  max-width: 411px;
  margin: auto;
}

.YoutubeVideoFrame {
  border-radius: 15px;
  background-color: white;
  margin-top: 40px;
}

.font-larger {
  color: var(--color-secondary);
}

.Link {
  cursor: pointer;
  color: #3290e0;
  line-height: 20px;
  margin-top: 28.44px;
}

.AnotherLink {
  cursor: pointer;
  color: var(--color-gray);
  line-height: 20px;
  margin-top: 28.44px;
  text-decoration: underline;
}

.modal_button {
  width: 100%;
  font-size: 1.4em;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  /* font-family: Helvetica-medium; */
}

.max-width-box-form {
  max-width: 411px;
}

.font-lg-16 {
  font-size: 20.35px;
}

.ContainerMeetingOf {
  max-width: 411px;
  margin: auto;
}

@media only screen and (max-width: 528px) {
  .info_box {
    height: 300px;
  }
}

.div_center_button {
  position: relative;
  margin-top: 20px;
}

#video {
  width: 100%;
}
</style>
